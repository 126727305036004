import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carrouselBlocks = document.querySelectorAll('.rounded-img-carrousel');

  if (0 < carrouselBlocks.length) {
    carrouselBlocks.forEach((carrouselBlock) => {
      new Swiper(
        carrouselBlock.querySelector('.rounded-img-carrousel__carrousel'),
        {
          slidesPerView: '1',
          watchOverflow: true,
          loop: false,
          spaceBetween: 16,

          navigation: {
            prevEl: carrouselBlock.querySelector('.swiper-button-prev'),
            nextEl: carrouselBlock.querySelector('.swiper-button-next'),
          },

          a11y: {
            firstSlideMessage: _x(
              'This is the first slide',
              'swiper label',
              'vtx'
            ),
            lastSlideMessage: _x(
              'This is the last slide',
              'swiper label',
              'vtx'
            ),
            prevSlideMessage: _x('Go to previous slide', 'swiper label', 'vtx'),
            nextSlideMessage: _x('Go to next slide', 'swiper label', 'vtx'),
            slideLabelMessage: _x(
              'Slide {{index}} of {{slidesLength}}',
              'swiper label',
              'vtx'
            ),
            paginationBulletMessage: _x(
              'Go to slide {{index}}',
              'swiper label',
              'vtx'
            ),
          },
        }
      );
    });
  }
});
